class ZIDXSavedSearchForm {
    // Do we really need this?
    // add the data fields for the form here:
    email:string="";

    // this allows array access
    [key:string]:any;

    qs: string = "";

    dialogContainer:HTMLDivElement;
    formElement:HTMLFormElement|null=null;
    lastDialogFormData:any={};
    formDialog:JQuery;
    errorMessageId: string = "ZIDXErrorMessage";

    constructor() {
        let self=this;
        this.dialogContainer=(<HTMLDivElement>document.getElementById("zidxSiteHomeSavedSearch")!);
        this.formDialog = ZIDX.$(this.dialogContainer).dialog({
            autoOpen: false,
            width: "auto",
            height: "auto",
            resizable:false,
            draggable:false,
            modal: true,
            open: function( event:any, ui:any ) {
                ZIDX.$("body").css({ overflow: 'hidden' });
                let formConfig=new ZIDXFormConfig();
                formConfig.action="/saved-search/fake"; // not used because we do ajax request below instead.
                formConfig.cancelURL="";
                formConfig.disableButtons=true;
                formConfig.formFields=[];

                self.getFormConfig(formConfig);
                let formHelper=new ZIDXFormHelper();
                let extraData={
                    // useful if we need to pass more data
                    "saved_search_format": 1,
                    "saved_search_frequency": 1,
                };
                let formHtml = formHelper.getForm(formConfig, self, extraData);
                self.dialogContainer.innerHTML=`<div><div style="display: none;"><b>Please correct these items and try again.</b><br><p id="${self.errorMessageId}"></p></div>${formHtml}</div>`;
                self.formElement=<HTMLFormElement>document.getElementById(formHelper.formId)!;
                self.lastDialogFormData=ZIDX.getFormDataByFormId(self.formElement.id);
                formHelper.setupEvents();
                location.hash = "zidxDialogOpen";
                window.zidxBase.setFieldFromLocalStorage("#zidxSiteHomeSavedSearch", "email", "saved_search_email");
            },
            buttons: {
                "Save": function () {
                    self.lastDialogFormData=ZIDX.getFormDataByFormId((self.formElement!).id);
                    let data = ZIDX.getFormDataByFormId((self.formElement!).id);
                    self.saveForm(data);
                },
                Cancel: function () {
                    self.formDialog.dialog("close");
                }
            },
            beforeClose: function(event:any, ui:any) {
                let rs=self.hasFormDataChanged(self.formElement!, self.lastDialogFormData);
                if(rs.changed){
                    if(confirm("Are you sure you want to go back? Changes you made may not be saved.")) {
                        self.lastDialogFormData=rs.data;
                    }else{
                        return false;
                    }
                }
                if(location.hash != null && location.hash == "#zidxDialogOpen"){
                    history.back();
                    return false;
                }
                return true;
            },
            close: function () {
                ZIDX.$("body").css({ overflow: 'inherit' });
            }
        });
        ZIDX.$(window).bind('hashchange', function () {
            if (location.hash == null || location.hash == "") {
                self.formDialog.dialog("close");
            }
        });

        ZIDX.$(window).on("clientresize resize", ()=> {
            this.resizeDialog();
        });
        this.resizeDialog();
        setTimeout(()=>{
            this.resizeDialog();
        },100);
        this.formDialog.dialog("open");
    }
    resizeDialog(){
        if(ZIDX.$(window).width()<992) {
            ZIDX.$(this.dialogContainer).dialog({
                width: Math.min(500, ZIDX.$(window).width() - 20),
                height: Math.min(400, ZIDX.$(window).height()-20)
            });
            ZIDX.$(this.dialogContainer).dialog( "option", "position", { my: "center", at: "center", of: window } );
        }else{
            ZIDX.$(this.dialogContainer).dialog({
                width: Math.min(500, ZIDX.$(window).width()-50),
                height: Math.min(400, ZIDX.$(window).height()-50)
            });
            ZIDX.$(this.dialogContainer).dialog( "option", "position", { my: "center", at: "center", of: window } );
        }
    }
    hasFormDataChanged(formObj:HTMLFormElement, cachedData:any):any{
        if(formObj.id == ""){
            return {changed:false};
        }
        let newData=ZIDX.getFormDataByFormId(formObj.id);
        let changed=false;
        for(let i in cachedData){
            if(typeof newData[i]== "undefined"){
                changed=true;
                break;
            }else if(newData[i] != cachedData[i]){
                if(i != "g-recaptcha-response" && i != "form_filling_data" && i != "form_email" && i != "form_session_id"){
                    changed=true;
                    break;
                }
            }
        }
        for(let i in newData){
            if(i != "g-recaptcha-response" && typeof cachedData[i]== "undefined"){
                changed=true;
                break;
            }
        }
        return {changed:changed, data:newData};
    }

    getFormConfig(formConfig:ZIDXFormConfig){
        // console.log("displayForm row:", this);
        let field=new ZIDXFormField();
        field.name="saved_search_email";
        field.label="Email";
        field.required = true;
        field.type=ZIDXFormFieldType.EMAIL;
        formConfig.formFields.push(field);

        field = new ZIDXFormField();
        field.label = "Format";
        field.name="saved_search_format";
        field.type = ZIDXFormFieldType.RADIO;
        field.required = true;
        field.values = [
            new ZIDXFormFieldValue("HTML Text w/Photos", "1"),
            new ZIDXFormFieldValue("Plain Text", "2"),
        ]
        formConfig.formFields.push(field);


        field = new ZIDXFormField();
        field.label = "Frequency";
        field.name="saved_search_frequency";
        field.type = ZIDXFormFieldType.RADIO;
        field.required = true;
        field.values = [
            new ZIDXFormFieldValue("Every Day ", "1"),
            new ZIDXFormFieldValue("Fridays", "2"),
        ]
        formConfig.formFields.push(field);



        // field=new FormField();
        // field.name="widgetType";
        // field.label="Widget Type";
        // field.type=FormFieldType.SELECT;
        // for(let i=0;i<editor.widgetTypes.length;i++){
        //     let type=editor.widgetTypes[i];
        //     field.values.push(new FormFieldValue(type.label, type.value));
        // }
        // formConfig.formFields.push(field);
    }
    saveForm(data:any){
        let self=this;
        // I don't see any usage of this
        // update class fields
        for(var field in data){
            if(this.hasOwnProperty(field)){
                if(typeof this[field]=="number"){
                    this[field]=parseFloat(data[field]);
                }else if(typeof this[field]=="boolean"){
                    this[field] = data[field] == "1";
                }else if(typeof this[field]=="string"){
                    this[field]=data[field];
                }
            }
        }
        // to help debug form data changes
        data["saved_search_criteria"] = this.qs;
        console.log(data, this);

        // makes ajax call to java controller SavedSearchSaveController /save-search/
        let obj = {
            id: "ajaxSaveSearch",
            method: "post",
            postObj: data,
            ignoreOldRequests: false,
            callback: function(r:string){
                let js=JSON.parse(r);
                if(js.success){
                    let savedSearches:any=localStorage.getItem("zidx.savedSearches");
                    if(savedSearches==null){
                        savedSearches={};
                    }else{
                        savedSearches=JSON.parse(savedSearches);
                    }
                    let data=JSON.parse(js.data);
                    data.saved_search_email=ZIDX.$("#zidxSiteHomeSavedSearch #saved_search_email").val();
                    savedSearches[data.saved_search_id]=data;
                    localStorage.setItem("zidx.savedSearches", JSON.stringify(savedSearches));
                    localStorage.setItem("zidx.email", data.saved_search_email);
                    self.formDialog.dialog("close");
                }else{
                    self.showError(js.errorMessage);
                }
            },
            errorCallback: function(r:string){
                self.showError(r);
            },
            url: ZIDX.getPrefixURL("/save-search/")
        };
        ZIDX.ajaxRequest(obj);

    }

    showError(message: string) {
        ZIDX.$(`#${this.errorMessageId}`)
        .text(message)
            .parent().show();
    }
}